<template>
  <AForm layout="vertical" labelAlign="left">
    <div style="overflow-x: hidden; overflow-y: scroll; height: 490px">
      <ARow :gutter="12">
        <ACol :span="12">
          <AFormItem label="Target Account" v-bind="validateInfos['accountId']">
            <ASelect
              v-model:value="state.accountId"
              placeholder="Target Account"
              allow-clear
            >
              <ASelect-option
                v-for="account of accounts"
                :value="account._id"
                :key="account._id"
              >
                {{ account.accountName }}
              </ASelect-option>
            </ASelect>
          </AFormItem>
        </ACol>

        <ACol :span="12">
          <div class="autopay">
            <span>Auto Pay</span>
            <ASwitch
              v-model:checked="state.autopay"
              checked-children="ON"
              un-checked-children="OFF"
              style="margin-left: 0.5rem"
            />
          </div>
        </ACol>
      </ARow>
      <ATabs v-model:active-key="state.type" class="custom-ant-tabs">
        <ATabPane key="credit_card" :disabled="!hasLawpayIntegration">
          <template #tab>
            <span>
              <CreditCardOutlined />
              <span class="tap-card-full">Credit/Debit Card</span>
              <span class="tap-card">Card</span>
            </span>
          </template>
          <!-- Card form -->
          <CardForm ref="refCardForm" :contactId="form.contact.id" />
        </ATabPane>
        <ATabPane key="cash">
          <template #tab>
            <span>
              <DollarCircleOutlined />
              Cash
            </span>
          </template>
          <img src="../assets/images/primalaw/money.svg" alt="" width="197" />
          <h3>
            Prima.Law will save the information for this payment plan.
          </h3>
          <h3>
            This information will be not sent to LawPay.
          </h3>
          <h3>
            If you would like to process payments electronically, please obtain
            a lawpay.com account and connect it to Prima.Law
          </h3>
        </ATabPane>
      </ATabs>
    </div>
    <slot :validateFn="validateForm" />
  </AForm>
</template>

<script lang="ts">
import { defineComponent, reactive, Ref, ref, toRaw, watchEffect } from 'vue';
import {
  CreditCardOutlined,
  DollarCircleOutlined,
  // FileProtectOutlined,
} from '@ant-design/icons-vue';
import CardForm from '@/components/CardForm.vue';
// import ECheckForm from '@/components/ECheckForm.vue';
import { useForm } from '@ant-design-vue/use';
import { PaymentFormType } from '@/types/payment-form.type';
import { useIntegrations, useGlobalProps } from '@/composables';
import { IntegrationsService } from '@/services';
import { Validator } from '@/utils/ant-custom-validators';

interface State {
  type: PaymentFormType;
  autopay: boolean;
  accountId: string | null;
}

export default defineComponent({
  components: {
    CardForm,
    // ECheckForm,
    CreditCardOutlined,
    DollarCircleOutlined,
    // FileProtectOutlined,
  },
  setup() {
    const { setPaymentMethodSelected, form } = useGlobalProps();
    const refCardForm = ref<typeof CardForm>() as Ref<typeof CardForm>;
    // const refECheckForm = ref<typeof ECheckForm>() as Ref<typeof ECheckForm>;

    const { hasLawpayIntegration, accounts, setAccounts } = useIntegrations();

    const state = reactive<State>({
      type: 'credit_card',
      autopay: false,
      accountId: null,
    });

    const rules = reactive({
      accountId: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
    });

    const primaAccounts = ref<any[]>([]);

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(state, rules);

    /** ----- Methods ------ */
    const filterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    /** Validate form and return data */
    const validateForm = async () => {
      if (state.type === 'credit_card') {
        // Credit card mode (lawpay)
        try {
          const lawPayRef = refCardForm;
          // const lawPayRef =
          //   state.type === 'credit_card' ? refCardForm : refECheckForm;

          if (!lawPayRef.value.savedPaymentMethod.id) {
            const [, layPawIsDone] = await Promise.all([
              validate('accountId'),
              lawPayRef.value.validateFn(),
            ]);

            if (!layPawIsDone) {
              throw 'Invalid form fields';
            }
          } else {
            const accountIsDone = await Promise.all([validate('accountId')]);

            if (!accountIsDone) {
              throw 'Invalid account field';
            }
          }

          // Validation successfully
          const lawpay = await lawPayRef.value.lawPayRequest();
          return Promise.resolve({ ...toRaw(state), lawpay });
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        // Cash mode
        let done = true;

        // Validate other fields
        try {
          await validate();
        } catch (error) {
          done = false;
        }

        if (!done) {
          return Promise.reject(new Error('Invalid form values'));
        }

        return Promise.resolve(toRaw(state));
      }
    };

    const setStateType = () => {
      if (!hasLawpayIntegration.value) {
        state.type = 'cash';
        return;
      }
    };

    /** Execute request on create component */
    const executeRequests = async () => {
      if (accounts.value.length) return;

      // Get accounts
      try {
        primaAccounts.value = await IntegrationsService.getAccounts();
        setAccounts(primaAccounts.value);
        setStateType();
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    const alternAccounts = () => {
      state.accountId = null;

      if (state.type === 'credit_card') {
        setAccounts(
          primaAccounts.value.filter(
            account =>
              account.lawPayAccount !== null &&
              account.lawPayAccount.some(
                (lawPayAccount: any) => lawPayAccount.type === 'card',
              ),
          ),
        );
      } else setAccounts(primaAccounts.value);

      return;
    };

    watchEffect(() => {
      alternAccounts();

      setPaymentMethodSelected(state.type);
    });

    return {
      state,
      accounts,
      validateInfos,
      hasLawpayIntegration,
      filterOption,
      validateForm,
      refCardForm,
      alternAccounts,
      primaAccounts,
      setStateType,
      // refECheckForm,
      form,
    };
  },
});
</script>

<style lang="scss">
.Contact {
  display: flex;
}

.card-brand {
  position: absolute;
  right: 8px;
  height: 24px;
  top: 3px;
  pointer-events: none;
}

.autopay {
  display: flex;
  justify-content: flex-end;
  height: 94px;
  align-items: center;
}

.tap-card {
  @include screen-sm {
    display: none;
  }

  &-full {
    display: none;

    @include screen-sm {
      display: inline;
    }
  }
}

.custom-ant-tabs {
  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 50%;
    margin: 0;
  }

  .ant-tabs-ink-bar {
    width: 50% !important;
  }
}
</style>
