
import { message } from 'ant-design-vue';
import { computed, defineComponent, reactive, toRefs } from 'vue';
import {
  RightOutlined,
  LeftOutlined,
  CheckOutlined,
  DownloadOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Step5 from './Step5.vue';

import { UserService, PaymentPlanService } from '@/services';
import { useIntegrations, useGlobalProps } from '@/composables';
import moment from 'moment';

export default defineComponent({
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    RightOutlined,
    LeftOutlined,
    CheckOutlined,
    DownloadOutlined,
    CloseOutlined,
  },
  props: {
    currentContact: {
      type: Object,
    },
  },
  setup() {
    /** State */
    const {
      paymentMethodSelected,
      setForm,
      setLoading,
      setTenantPictureBase64,
      user,
    } = useGlobalProps();
    const { setIntegrations } = useIntegrations();

    const data = reactive({
      /** Current step */
      step: 0,
      /** Steps data */
      steps: [
        { title: 'Contact', description: 'Details' },
        { title: 'Plan', description: 'Invoices' },
        { title: 'Review', description: 'Draft' },
        { title: 'Payment', description: 'Method' },
        { title: 'Summary', description: 'Finish' },
      ],
    });

    /** Dynamic current step component */
    const stepComponent = computed(() => {
      return `Step${data.step + 1}`;
    });

    /** Form all steps */
    const form: any = {};

    /** Methods */
    window.addEventListener('message', e => {
      const primaUrl = process.env.VUE_APP_PRIMA_URL.replace('com/', 'com');
      // Get the sent data
      if (e.origin === primaUrl) {
        const data = e.data;

        setTenantPictureBase64(data);
      }

      // If you encode the message in JSON before sending them,
      // then decode here
      // const decoded = JSON.parse(data);
    });

    /** Go to next step */
    const nextStep = () => data.step++;

    /** Go to previous step */
    const prevStep = () => data.step--;

    /** Create payment plan */
    const createPaymentPlan = async (result: any) => {
      const tmpUser: any = user.value;

      try {
        const newPaymentPlan: any = {
          contactId: form.value.contact.id,
          caseId: form.value.case.id,
          totalPlan: form.value.totalPayment,
          firstPay:
            form.value.initialPayment.value === ''
              ? null
              : form.value.initialPayment.value,
          porcentage: form.value.initialPayment.modo === '%',
          quantityPays: form.value.payments,
          periodPays:
            form.value.period === 1
              ? 'weekly'
              : form.value.period === 2
              ? 'twoWeeks'
              : form.value.period === 3
              ? 'threeWeeks'
              : 'monthly',
          startDatePay: moment(form.value.initialPayment.payOn).format(
            'MM/DD/YYYY',
          ),
          paymentMethodId: result.lawpay ? result.lawpay.id : null,
          accountId: result.accountId,
          description: form.value.description,
          payingParty: form.value.payingParty.fullName,
          termsAndConditions:
            tmpUser.termsAndConditions && tmpUser.termsAndConditions !== null
              ? tmpUser.termsAndConditions
              : 'Missing Terms & Conditions.',
        };

        /** Mutation create payment plan */
        const { d }: any = await PaymentPlanService.create(newPaymentPlan);

        console.log(d);

        setLoading(false);

        message.success('Processing complete!');
        // Go to next page
        nextStep();
      } catch (error) {
        console.error(error);
      }
    };

    /** Hanlder for close stepper */
    const onClose = () => {
      window.top.postMessage(
        'close payment plan',
        process.env.VUE_APP_PRIMA_URL,
      );
    };

    /** Handler for done stepper */
    const onDone = async (validator: () => Promise<any>) => {
      try {
        const result = await validator();

        setLoading(true);

        createPaymentPlan(result);
      } catch (error) {
        console.error(error);
      }
    };

    /** Current "submit" event */
    const onSubmit = async (validateFn: () => Promise<any>) => {
      // Validation function not provided (continue for now)
      if (!validateFn) {
        nextStep();
        return;
      }

      try {
        // Data returned of the current step
        const data = await validateFn();
        // Save data in this component
        form.value = Object.assign({}, form.value, data);

        setForm(form.value);

        // Go to next page
        nextStep();
      } catch (error) {
        // Form error
        console.error(error);
      }
    };

    const executeRequests = async () => {
      try {
        // Get integrations
        const integrations = await UserService.getIntegrations();
        setIntegrations(integrations);
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    const onDonwload = async (download: () => Promise<any>) => {
      await download();
    };

    return {
      ...toRefs(data),
      stepComponent,
      nextStep,
      prevStep,
      onClose,
      onDone,
      onSubmit,
      onDonwload,
      paymentMethodSelected,
    };
  },
});
